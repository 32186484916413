(function () {
  'use strict';
  /* global _, document */
  class QuadroOcorrenciasCtrl {
    constructor(Authenticator, Ocorrencias, $uibModal, $interval, ServiceVfiltro, AlertMessage, AlertasEnum, ObjetosVfiltroService, FactoryVfiltro,
      FeatureFlag, Keys, OcorrenciasEnum, $scope, $translate, $window) {
      this.modalService = $uibModal;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.keys = Keys;
      this.ocorrenciasEnum = OcorrenciasEnum;
      this.intervalService = $interval;
      this.ocorrenciasService = Ocorrencias;
      this.alertMessage = AlertMessage;
      this.alertasEnum = AlertasEnum;
      this.scope = $scope;
      this.intervaloConsulta = 60000;
      this.initMsg();
      this.featureFlag = FeatureFlag;
      this.hasConfigHistoricoAlerta = false;
      this.eventsOrder = [];
      this.cardsOrderOptions = [
        {
          key: 'mostRecent',
          label: 'ce.tempoReal.quadroDeOcorrencias.orderOptions.mostRecent'
        },
        {
          key: 'recurrent',
          label: 'ce.tempoReal.quadroDeOcorrencias.orderOptions.recurrent'
        },
        {
          key: 'mostEvents',
          label: 'ce.tempoReal.quadroDeOcorrencias.orderOptions.mostEvents'
        },
        {
          key: 'assignedToMe',
          label: 'ce.tempoReal.quadroDeOcorrencias.orderOptions.assignedToMe'
        }
      ];
      this.cardsOrder = 'mostRecent';

      this.entreEmContatoI18N = $translate.instant('ce.tempoReal.quadroDeOcorrencias.entreEmContato');
      this.erroRealizarTratativa = $translate.instant('ce.tempoReal.quadroDeOcorrencias.erroRealizarTratativa');
      this.naoPossuiPermissaoOcorrencia = $translate.instant('ce.tempoReal.quadroDeOcorrencias.naoPossuiPermissaoOcorrencia');
      this.ocorrencias = [];
      this.usuario = null;
      this.window = $window;

      Authenticator.getUser().then(user => {
        this.usuario = user;
        this.hasConfigHistoricoAlerta = user.permissoes.includes('HISTORICO_ALERTA_VISUALIZAR');
      });

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'uoId',
          key: this.keys.uo.name,
          size: 12
        },
        {
          name: 'veiculos',
          key: this.keys.veiculos.name,
          ignoreValidation: true,
          size: 12
        },
        {
          key: this.keys.usuario.name,
          size: 6
        },
        {
          key: this.keys.nivel.name,
          size: 6
        },
        {
          key: this.keys.tipos.name,
          size: 12
        }
      ]))
        .then(vfiltro => {
          this.vfiltro = vfiltro;
          this.uoId = vfiltro.find(x => x.key === this.keys.uo.name).value.id;
          const cardsOrderLocalstorage = this.window.localStorage.getItem(`safety-focus-cards-order-${this.usuario.id}`);

          this.cardsOrder = cardsOrderLocalstorage ? cardsOrderLocalstorage : this.cardsOrder;

          this.consultar();
          this.startOcorrencias();
          if (this.featureFlag.SHOW_EVENT_DIALOG) {
            this.showOpenDialogEventPriority();
            this.getEventPriorityConfigs();
          }
          this.permissaoEditarOcorrencia = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('OCORRENCIA_EDITAR');
        })
        .catch(() => {
          this.serviceVfiltro.openFiltroDetalhado = true;
        });
    }

    getEventPriorityConfigs() {
      this.ocorrenciasService.getEventPriorityConfigsByUo(this.uoId)
        .then(response => {
          const eventsOrderLocalstorage = this.window.localStorage.getItem(`event-priority-${this.uoId}`);

          if (response.data.some((i) => i.presentationPriority)) {
            this.eventsOrderByUo = response.data
              .sort((a, b) => (a.presentationPriority || response.data.length) - (b.presentationPriority || response.data.length))
              .map(event => event.tipo);
          } else {
            const list = this.getAlertasFiltrados();
            this.eventsOrderByUo = list
              .sort((a, b) => (a.eventOrder || list.length) - (b.eventOrder || list.length))
              .map(alerta => alerta.id);
          }

          this.eventsOrder = eventsOrderLocalstorage ? eventsOrderLocalstorage.split(',') : this.eventsOrderByUo;
        });
    }

    getAlertasFiltrados() {
      return this.alertasEnum.getTipos().filter(alerta => {
        return alerta.id !== 'EXCESSO_VELOCIDADE' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_20' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_30' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_ACIMA_30' &&
          alerta.id !== 'EXCESSO_VELOCIDADE_POR_VIA' &&
          alerta.id !== 'DIAGNOSTICO_OBD' &&
          alerta.id !== 'VEICULO_SEM_SINAL' &&
          alerta.id !== 'RFID_NAO_CADASTRADO' &&
          alerta.id !== 'SEM_IDENTIFICACAO_DE_MOTORISTA' &&
          alerta.id !== 'GENERIC' &&
          alerta.id !== 'SEM_EPI';
      });
    }

    startOcorrencias() {
      const interval = this.intervalService(() => {
        this.consultar();
      }, this.intervaloConsulta);

      this.scope.$on('$destroy', () => {
        this.intervalService.cancel(interval);
      });
    }

    consultar() {
      let vfiltroParams = this.serviceVfiltro.getFiltroBackendNovo(this.vfiltro);
      const veiculosFiltered = this.vfiltro.find((f) => f.key === 'veiculos'),
          usuarioIdFiltro = vfiltroParams.usuario,
          tiposFiltered = this.vfiltro.find((f) => f.key === 'tipos'),
          nivelFiltro = vfiltroParams.nivel,
          veiculoIdsFiltro = veiculosFiltered ?
            veiculosFiltered.values.map((veiculo) => veiculo.id) :
            veiculosFiltered,
          tiposFiltro = tiposFiltered ?
            tiposFiltered.values.map((tipo) => tipo.id) :
            tiposFiltered;

      this.setFiltroUsuarioVeiculos(usuarioIdFiltro, veiculoIdsFiltro, tiposFiltro, nivelFiltro);

      this.ocorrenciasService.getQuadroOcorrencias(vfiltroParams)
        .then(response => {
          if (response && response.data) {
            const allOcurrences = response.data.NOVA.concat(response.data.EM_TRATATIVA, response.data.RESOLVIDA);
            this.ocorrenciasByMotorista = this.getOcorrenciasByMotorista(allOcurrences);
            this.handleOcurrenceResponse(response.data);
          }
        })
        .catch(() => {
          this.setMsgErrorRequest();
        });
    }

    handleOcurrenceResponse(response) {
      let ocorrenciasNova = response.NOVA,
          ocorrenciasTratativa = response.EM_TRATATIVA,
          ocorrenciasResolvida = response.RESOLVIDA;

      this.ocorrenciasNova = this.getOcorrenciasSortedAndFiltered(ocorrenciasNova);
      this.ocorrenciasTratativa = this.getOcorrenciasSortedAndFiltered(ocorrenciasTratativa);
      this.ocorrenciasResolvida = this.getOcorrenciasSortedAndFiltered(ocorrenciasResolvida);

      this.ocorrencias = this.ocorrenciasNova.concat(this.ocorrenciasTratativa, this.ocorrenciasResolvida);

      if (!this.ocorrencias.length) {
        this.getConfigs();
      } else {
        this.initOcorrencias(false);
        this.initMsg();
        this.verifyOcorrenciaInLocalStorage();
      }
      this.errorRequest = false;
    }

    setFiltroUsuarioVeiculos(usuarioId, veiculoIds, tiposFiltro, nivelFiltro) {
      this.filtroUsuarioVeiculos = (ocorrencia) => {
        const hasUsuario = angular.isUndefined(usuarioId) ||
              ocorrencia.usuarioIdAtribuido === usuarioId,
            hasVeiculo = angular.isUndefined(veiculoIds) ||
            veiculoIds.some(
              veiculoId => veiculoId === ocorrencia.veiculoId
            ),
            hasTipos = angular.isUndefined(tiposFiltro) || this.handleTiposFiltro(ocorrencia, tiposFiltro, nivelFiltro);
        return hasUsuario && hasVeiculo && hasTipos;
      };
    }

    handleTiposFiltro(ocorrencia, tiposFiltro, nivelFiltro) {
      return tiposFiltro.some((tipo) => {
        const isExcessoVelocidade = tipo === 'EXCESSO_VELOCIDADE',
            hasNivel = angular.isDefined(nivelFiltro);

        return ocorrencia.alertasGerados.some((alerta) => {
          if (isExcessoVelocidade) {
            return hasNivel ? alerta.tipo === `EXCESSO_VELOCIDADE_${nivelFiltro}` : alerta.tipo.includes('EXCESSO_VELOCIDADE');
          }
          return alerta.tipo === tipo;
        });
      });
    }

    getConfigs() {
      this.ocorrenciasService.getConfigsByUo(this.uoId)
        .then(res => {
          if (!res.data.length) {
            this.setMsgSemConfig();
          } else {
            this.setMsgSemOcorrencia();
          }
        })
        .catch(() => {
          this.setMsgErrorRequest();
        });
    }

    initMsg() {
      this.errorRequest = false;
      this.loaded = false;
      this.hasConfigs = true;
    }

    verifyOcorrenciaInLocalStorage() {
      const ocorrenciaId = this.window.localStorage.getItem('localizacao-frota-go-to-quadro-ocorrencias-ocorrenciaId');
      if (ocorrenciaId) {
        const ocorrenciaNovaMaisRecente = this.ocorrenciasNova.find(ocorrencia => ocorrencia.id === Number(ocorrenciaId));
        this.openDetalhes(ocorrenciaNovaMaisRecente);
        this.window.localStorage.removeItem('localizacao-frota-go-to-quadro-ocorrencias-ocorrenciaId');
      }
    }

    setMsgSemConfig() {
      this.errorRequest = false;
      this.loaded = false;
      this.hasConfigs = false;
    }

    setMsgSemOcorrencia() {
      this.errorRequest = false;
      this.loaded = true;
      this.hasConfigs = true;
    }

    setMsgErrorRequest() {
      this.errorRequest = true;
      this.loaded = false;
      this.hasConfigs = true;
    }

    initOcorrencias(isUpdate) {
      if (isUpdate) {
        this.ocorrencias = this.getOcorrenciasSortedAndFiltered(this.ocorrencias);
      }
      this.ocorrenciasByStatus = this.getOcorrenciasByStatus(isUpdate);

      this.createStatusEmpty();
    }

    createStatusEmpty() {
      this.ocorrenciasEnum.getKeys().forEach(statusKey => {
        if (angular.isUndefined(this.ocorrenciasByStatus[statusKey])) {
          this.ocorrenciasByStatus[statusKey] = [];
        }
      });
    }

    getOcorrenciasSortedAndFiltered(ocorrencias) {
      ocorrencias
        .sort((a, b) => new Date(b.dataHora).getTime() - new Date(a.dataHora).getTime())
        .forEach((ocorrencia) => {
          ocorrencia.isRecorrente = this.isRecorrente(ocorrencia);
          ocorrencia.sortableQty = ocorrencia.alertasGerados.reduce(
            (acc, alerta) => acc + alerta.quantidade,
            0
          );
        });

      const sorted = this.sortCards(ocorrencias, this.cardsOrder);

      return sorted.filter(this.filtroUsuarioVeiculos);
    }

    sortCards(ocorrencias, type) {
      switch (type) {
        case 'mostRecent':
          return ocorrencias;
        case 'recurrent':
          return ocorrencias.sort((a, b) => b.isRecorrente - a.isRecorrente);
        case 'mostEvents':
          return ocorrencias.sort((a, b) => b.sortableQty - a.sortableQty);
        case 'assignedToMe':
          if (this.usuario.id) {
            return ocorrencias.sort((a, b) => {
              if (a.usuarioIdAtribuido !== b.usuarioIdAtribuido) {
                if (a.usuarioIdAtribuido === this.usuario.id) {
                  return -1;
                } else if (b.usuarioIdAtribuido === this.usuario.id) {
                  return 1;
                }
              }
            });
          }
          return ocorrencias;
        default:
          return ocorrencias;
      }
    }

    onChangeCardsOrder() {
      this.window.localStorage.setItem(`safety-focus-cards-order-${this.usuario.id}`, this.cardsOrder);
      this.handleOcurrenceResponse(this.ocorrenciasByStatus);
    }

    getOcorrenciasByMotorista(occurrences) {
      return _.groupBy(occurrences, ocorrencia => ocorrencia.motorista && ocorrencia.motorista.id);
    }

    getOcorrenciaById(id) {
      return this.ocorrencias.find(ocorrencia => ocorrencia.id === id);
    }

    getOcorrenciasByStatus(isUpdate) {
      if (isUpdate) {
        return _.groupBy(this.ocorrencias, ocorrencia => {
          return ocorrencia.status;
        });
      }
      return {
        NOVA: this.ocorrenciasNova,
        EM_TRATATIVA: this.ocorrenciasTratativa,
        RESOLVIDA: this.ocorrenciasResolvida
      };
    }

    isRecorrente(ocorrencia) {
      if (ocorrencia.motorista) {
        let idMotorista = ocorrencia.motorista.id,
            lastIndex = this.ocorrenciasByMotorista[idMotorista].length - 1;
        return this.ocorrenciasByMotorista[idMotorista][lastIndex].id !== ocorrencia.id;
      }
      return false;
    }

    generateIdTrackBy(ocorrencia) {
      return `${ocorrencia.id},${ocorrencia.usuarioIdAtribuido},${ocorrencia.alertasGerados.length},${ocorrencia.alertasGerados.map(alerta => alerta.quantidade)},${this.eventsOrder.join(',')}`;
    }

    updateOcorrencia(idOcorrencia, novoStatus, usuarioAtribuido) {
      const ocorrenciaUpdated = this.getOcorrenciaById(idOcorrencia);
      ocorrenciaUpdated.status = novoStatus;
      if (usuarioAtribuido) {
        ocorrenciaUpdated.usuarioIdAtribuido = usuarioAtribuido.id;
        ocorrenciaUpdated.usuarioAtribuido = usuarioAtribuido;
      }
      this.initOcorrencias(true);
    }

    showOpenDialogEventPriority() {
      /* eslint-disable */
      const abrirModal = document.querySelector('#openDialogEventPriority'),
        modal = document.createElement('vfwc-open-dialog-event-priority'); /* eslint-enable */
      modal.uoId = this.uoId;
      abrirModal.appendChild(modal);
      this.onEventPriority(modal);
    }

    onEventPriority(element) {
      element.addEventListener('eventPriorityEmitter', (event) => {
        this.eventsOrder = event.detail && event.detail.length ? event.detail : this.eventsOrderByUo;
        this.consultar();
      });
    }

    openDetalhes(ocorrencia) {
      if (!this.hasConfigHistoricoAlerta) {
        return this.errorPermissionAlert();
      }
      let ocorrenciasByMotorista = [];
      if (ocorrencia.motorista) {
        ocorrenciasByMotorista = this.ocorrenciasByMotorista[ocorrencia.motorista.id];
      }
      /* eslint-disable */
      const abrirModal = document.querySelector('#abrirModal'),
        modal = document.createElement('vfwc-abrir-ocorrencia-dialog'); /* eslint-enable */
      modal.ocorrenciaId = ocorrencia.id;
      modal.ocorrenciaDatahora = ocorrencia.dataHora;
      modal.veiculoIdOcorrencia = ocorrencia.veiculo.id;
      modal.ocorrenciasMotorista = ocorrenciasByMotorista;
      modal.usuario = this.usuario;
      abrirModal.appendChild(modal);
      this.onOcorrenciaAtualizada(modal);
    }
    errorPermissionAlert() {
      /* eslint-disable */
      const openAlert = document.querySelector('#openAlert');
      const oldAlert = openAlert.querySelector('vfwc-toast-alert-generic');
      if (oldAlert) {
        openAlert.removeChild(oldAlert);
      }
      const alert = document.createElement('vfwc-toast-alert-generic');
      alert.text = this.naoPossuiPermissaoOcorrencia;
      openAlert.append(alert);
    }
    onOcorrenciaAtualizada(element) {
      element.addEventListener('emiteAlteracaoOcorrencia', (event) => {
        if (event.detail && event.detail.ocorrencia) {
          const ocorrencia = event.detail.ocorrencia;
          this.updateOcorrencia(ocorrencia.id, ocorrencia.status, ocorrencia.usuarioAtribuido);
        }
        if (event.detail && event.detail.ocorrenciaAnexo) {
          const ocorrencia = event.detail.ocorrenciaAnexo;
          this.openDetalhes(ocorrencia);
        }
      });

      element.addEventListener('hasAlertsPurgedEmitter', (event) => {
        if (event.detail) {
          this.consultar();
        }
      });
    }

    emiteErro() {
      this.error = true;
      this.alertMessage.create({
        type: 'error',
        message: this.erroRealizarTratativa,
        appendTo: '.alerta-aqui'
      });
    }
  }

  angular
    .module('quadroOcorrencias')
    .controller('QuadroOcorrenciasCtrl', QuadroOcorrenciasCtrl);
}());
