/* global document */
(function () {
  'use strict';

  function MultiVideoPlayer() {
    return {
      templateUrl: 'video/video-player/multi-video-player.tpl.html',
      controller: 'MultiVideoPlayerCtrl',
      controllerAs: 'multiVideoPlayer',
      restrict: 'E',
      scope: {
        idVideo: '@',
        snapshotId: '@',
        latitude: '@',
        longitude: '@',
        vehicleId: '@',
        vehiclePrefix: '@',
        vehiclePlate: '@',
        driverName: '@',
        datahora: '@',
        tipoEvento: '@',
        threshold: '@',
        eventDuration: '@',
        eventLevel: '@',
        actualValue: '@',
        resolve: '='
      }
    };
  }

  class MultiVideoPlayerCtrl {
    constructor($scope, moment, Authenticator, MultiVideoService, $stateParams) {
      this.moment = moment;
      this.authenticator = Authenticator;
      this.multiVideoService = MultiVideoService;
      this.modalElement = null;
      this.buildVideoParams($scope, $stateParams);
      this.configurarPlayerMultiVideo();
    }

    buildVideoParams(scope, stateParams) {
      this.idVideo = scope.resolve ? scope.resolve.idVideo : stateParams.idVideo;
      this.snapshotId = scope.resolve ? scope.resolve.snapshotId : stateParams.snapshotId;
      this.tipoEvento = scope.resolve ? scope.resolve.tipoEvento : stateParams.tipoEvento;
      this.latitude = scope.resolve ? scope.resolve.latitude : stateParams.latitude;
      this.longitude = scope.resolve ? scope.resolve.longitude : stateParams.longitude;
      this.vehicleId = scope.resolve ? scope.resolve.vehicleId : stateParams.vehicleId;
      this.vehiclePrefix = scope.resolve ? scope.resolve.vehiclePrefix : stateParams.vehiclePrefix;
      this.vehiclePlate = scope.resolve ? scope.resolve.vehiclePlate : stateParams.vehiclePlate;
      this.driverName = scope.resolve ? scope.resolve.driverName : stateParams.driverName;
      this.datahora = this.moment(scope.resolve ? scope.resolve.datahora : stateParams.datahora).toISOString();
      this.isDialog = angular.isDefined(scope.resolve);
      this.threshold = scope.resolve ? scope.resolve.threshold : stateParams.threshold;
      this.actualValue = scope.resolve ? scope.resolve.actualValue : stateParams.actualValue;
      this.eventDuration = scope.resolve ? scope.resolve.eventDuration : stateParams.eventDuration;
      this.eventLevel = scope.resolve ? scope.resolve.eventLevel : stateParams.eventLevel;

      this.coordinates = {
        latitude: this.latitude,
        longitude: this.longitude
      };
      this.vehicle = {
        id: this.vehicleId,
        prefixo: this.vehiclePrefix,
        placa: this.vehiclePlate
      };
    }

    configurarPlayerMultiVideo() {
      /*eslint-disable */
      this.modalElement = document.querySelector('.modal');
      const modalDialogElement = document.querySelector('.modal-dialog'),
          playerContainer = document.querySelector('#video-player-container');

      if (modalDialogElement) {
        modalDialogElement.style.width = '95vw';
        modalDialogElement.style.margin = '0px auto';
      }

      if (playerContainer) {
        this.authenticator.getUser().then(user => {
          if (user) {
            const playerMultiVideo = document.createElement('vfwc-dialog-event-viewer'); /*eslint-enable */
            playerMultiVideo.idVideo = this.idVideo;
            playerMultiVideo.dataHora = this.datahora;
            playerMultiVideo.user = user;
            playerMultiVideo.tipoEvento = this.tipoEvento;
            playerMultiVideo.snapshotId = this.snapshotId;
            playerMultiVideo.coordinates = this.coordinates;
            playerMultiVideo.vehicle = this.vehicle;
            playerMultiVideo.driverName = this.driverName;
            playerMultiVideo.isDialog = this.isDialog;
            playerMultiVideo.threshold = this.threshold;
            playerMultiVideo.actualValue = this.actualValue;
            playerMultiVideo.eventDuration = this.eventDuration;
            playerMultiVideo.eventLevel = this.eventLevel;
            playerContainer.appendChild(playerMultiVideo);
            this.onWatchedVideo(playerMultiVideo);
            this.onModalClosed(playerMultiVideo);
          }
        });
      }
    }

    onWatchedVideo(element) {
      element.addEventListener('markWatched', (event) => {
        const video = event.detail;
        this.multiVideoService.updateVideo(video);
      });
    }

    onModalClosed(element) {
      element.addEventListener('modalClosedEvent', (event) => {
        if (this.modalElement && event.detail.isModalClosed) {
          this.modalElement.remove();
        }
      });
    }
  }

  angular
    .module('videoPlayer')
    .controller('MultiVideoPlayerCtrl', MultiVideoPlayerCtrl)
    .directive('multiVideoPlayer', MultiVideoPlayer);
}());
