(function () {
  'use strict';
  const ATIVO = true, INATIVO = false;

  angular
    .module('FeatureFlagModule')
    .constant('FeatureFlag', {
      /**
       * Ativa ou inativa verificação de soluções.
       * @type {boolean}
       */
      SOLUCOES: ATIVO,
      SOLUCOES_AVISO: INATIVO,
      CONFIG_NOTIFICACAO_OCORRENCIA: ATIVO,
      SNAPSHOTS_HIST_DETALHADO: ATIVO,
      DOCUMENTOS_MOTORISTA: ATIVO,
      SNACKBAR_PRODUCT_VALIDATION: INATIVO,
      ADVISOR: ATIVO,
      VIDEO_LIBRARY: ATIVO,
      TUTORIAL_BUSCA_REGIAO: INATIVO,
      DIALOG_PONTO_REESTRUTURADO: ATIVO,
      VIGENCIA_PROGRAMADA: ATIVO,
      MENU_INSIGHTS: ATIVO,
      SOLUCAO_REC_FACIAL: ATIVO,
      EXPORTAR_PDF_HISTORICO_OCORRENCIAS: ATIVO,
      SHOW_EVENT_DIALOG: ATIVO,
      SHOW_HARSH_EVENTS_RISK_REGION: ATIVO,
      SHOW_ULTRAPASSAGEM_ILEGAL: ATIVO
    });
}());
