(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.prontuarioV2', {
      url: '/prontuario-v2/:idMotorista?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'prontuario-v2/prontuario.tpl.html',
          controller: 'ProntuarioV2Ctrl',
          controllerAs: 'prontuario'
        }
      },
      data: {
        titulo: 'ce.ranking.motoristas.prontuario.titulo',
        hideTitulo: true,
        roles: [['ADMIN_VELTEC']]
      }
    });
  }

  angular
    .module('ProntuarioV2Module')
    .config(pageConfig);
}());
