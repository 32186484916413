(function () {
  'use strict';

  class CardSnapshotCtrl {
    constructor($uibModal, UrlConfigService) {
      this.uibModal = $uibModal;
      this.urlConfigService = UrlConfigService;
    }

    $onInit() {
      this.qtdCameras = this.indiceDisponiveis.length;
    }

    getUrl() {
      const currentCamera = this.snapshot.camerasPhotoUrl.find(camera => camera.indice === this.indiceDisponiveis[this.indice]);
      if (currentCamera) {
        return currentCamera.urlDriverPhoto;
      }
      return 'https://veltec3g-o-que-ha-de-novo.s3-sa-east-1.amazonaws.com/Veltec+Trimble/veltec-background.png';
    }

    visualizarPonto() {
      this.verNoMapa({posicao: {
        latitude: this.snapshot.latitude,
        longitude: this.snapshot.longitude,
        horario: this.getTime()
      }});
    }

    visualizarFoto() {
      const snapshotSlide = {
        id: this.snapshot.id,
        dateTime: this.snapshot.dateTime,
        cameras: this.indiceDisponiveis.map(indice => {
          const currentCamera = this.snapshot.camerasPhotoUrl.find(camera => camera.indice === indice);
          return {
            indice: indice,
            possuiImagem: !!currentCamera,
            s3Url: currentCamera ? currentCamera.urlDriverPhoto : undefined
          };
        })
      };

      this.uibModal.open({
        component: 'carouselSnapshot',
        size: 'carousel',
        resolve: {
          snapshot: () => snapshotSlide,
          indice: () => this.indice
        }
      });
    }

    getTime() {
      return new Date(this.snapshot.dateTime).toLocaleTimeString();
    }
  }

  angular
    .module('historicoTimeline')
    .component('cardSnapshot', {
      controller: CardSnapshotCtrl,
      templateUrl: 'historico-timeline/visao-cameras/card-snapshot/card-snapshot.tpl.html',
      bindings: {
        snapshot: '<',
        indice: '<',
        indiceDisponiveis: '<',
        verNoMapa: '&'
      }
    });
}());
