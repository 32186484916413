/* global localStorage, window */
/* eslint-disable no-undef */
(function () {
  'use strict';

  angular
    .module('conducaoEconomica')
    .value('routeOnAuthFail', 'root.direcaoSegura')
    .config(config);

  function config($urlRouterProvider, $stateProvider, $httpProvider) {
    $urlRouterProvider.otherwise(($injector) => {
      let stateUrlService = $injector.get('StateUrlService');
      stateUrlService.setLastUrl();
    });

    $stateProvider.state('root', {
      url: '',
      abstract: true,
      views: {
        header: {
          templateUrl: 'header.tpl.html',
          controller: 'HeaderCtrl',
          controllerAs: 'headerCtrl'
        }
      },
      data: {
        titulo: '',
        tituloDescricao: ''
      },
      resolve: {
        auth: (Authenticator, UrlConfigService) => Authenticator.getUser().then(usuario => {
          if (usuario && usuario.uo && usuario.uo.id === 1) {
            /* eslint-disable */
            window.location.href = UrlConfigService.getPermissoesUrl();
            /* eslint-enable */
          }
          return usuario;
        }),
        translateReady: ['$translate', function ($translate) {
          return $translate.onReady();
        }]
      }
    })
    .state('unprotected', {
      abstract: true,
      views: {
        header: {
          template: '<div data-ui-view="guest@" class="view-content"></div>'
        }
      },
      resolve: {
        auth: (Authenticator) => Authenticator.init()
      }
    });

    $httpProvider.interceptors.push(['$q', 'Flash', '$window', '$injector', '$rootScope', ($q, Flash, $window, $injector, $rootScope) => {
      return {
        responseError: function (rejection) {
          let authenticator = $injector.get('Authenticator'),
              user = $rootScope.usuario;
          if (rejection.status === 401) {
            if (user) {
              const keysLocalStorage = Object.keys(angular.fromJson(angular.toJson(localStorage))),
                  userPreferencesThatHasUo = [
                    `vfiltro-${user.id}`,
                    `lastPage-${user.id}`,
                    `filtro-ponto-referencia-${user.id}`
                  ];
              keysLocalStorage.forEach(key => {
                if (userPreferencesThatHasUo.includes(key)) {
                  localStorage.removeItem(key);
                }
              });
            }
            localStorage.removeItem('vfleets-oauth2-authorization');

            authenticator.init();
          } else if (rejection.status === 403) {
            Flash.create('danger', 'Erro! Usuário sem permissão para acessar este recurso.');
          } else if (rejection.status === 404) {
            if (rejection.config.url.endsWith('/usuario') && !$window.location.pathname.includes('planos-solucoes-novo-usuario')) {
              $window.location.href = '/planos-solucoes-novo-usuario';
            } else {
              Flash.create('danger', 'Erro! Serviço não encontrado.');
            }
          } else {
            Flash.create('danger', 'Erro ao requisitar o servidor.');
          }
          return $q.reject(rejection);
        }
      };
    }]);
  }
}());
