(function () {
  'use strict';

  class ControleCamerasCtrl {
    constructor() {
      this.qtdCameras = this.indiceDisponiveis.length;
      this.viewIndex = 0;
    }

    classCamera(value) {
      return value === this.indiceDisponiveis[this.viewIndex] ? 'fa-circle' : 'fa-circle-o';
    }

    setIndex(value) {
      this.viewIndex = this.indiceDisponiveis.indexOf(value);
      this.emitToIndex(this.viewIndex);
    }

    next() {
      if (this.viewIndex !== this.qtdCameras - 1) {
        this.viewIndex++;
        this.emitToIndex(this.viewIndex);
      }
    }

    previous() {
      if (this.viewIndex !== 0) {
        this.viewIndex--;
        this.emitToIndex(this.viewIndex);
      }
    }

    isNextDisable() {
      return this.viewIndex === this.qtdCameras - 1;
    }

    isPreviousDisable() {
      return this.viewIndex === 0;
    }

    emitToIndex(value) {
      this.onToIndex({indice: value});
    }
  }

  angular
    .module('historicoTimeline')
    .component('controleCameras', {
      controller: ControleCamerasCtrl,
      templateUrl: 'historico-timeline/visao-cameras/controle-cameras/controle-cameras.tpl.html',
      bindings: {
        indiceDisponiveis: '<',
        onToIndex: '&'
      }
    });
}());
