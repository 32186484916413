(function () {
  'use strict';

  angular.module('ModuleConvertersUtil', [])

  .service('ConvertersService', function () {
    const KM_TO_MILE_CONVERSION = 0.621371,
        MILE_TO_KM_CONVERSION = 1 / KM_TO_MILE_CONVERSION,
        truncateMiles = (miles) => Math.floor(miles),
        roundUpKilometers = (kilometers) => Math.ceil(kilometers);

    this.handleKmToMiles = (km) => {
      if (km === null || angular.isUndefined(km)) {
        return km;
      }

      const parsedKm = angular.isString(km) ? parseFloat(km) : km;

      if (Number.isNaN(parsedKm)) {
        return km;
      }

      return truncateMiles(parsedKm * KM_TO_MILE_CONVERSION);
    };

    this.handleMilesToKm = (mile) => {
      if (mile === null || angular.isUndefined(mile)) {
        return mile;
      }

      const parsedMile = angular.isString(mile) ? parseFloat(mile) : mile;

      if (Number.isNaN(parsedMile)) {
        return mile;
      }

      return roundUpKilometers(parsedMile * MILE_TO_KM_CONVERSION);
    };
  })

  .filter('formatSpeedMeasure', function () {
    return function (speed, userSpeedMeasurement) {
      if (userSpeedMeasurement === 'MILES') {
        return `${speed} mph`;
      }
      return `${speed} KM/H`;
    };
  });
}());

