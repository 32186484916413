(function () {
  'use strict';

  class ModalRequisicaoVideoCtrl {
    constructor(AlertasEnum, moment, Restangular, AlertMessage, $translate, ServiceVfiltro, GoogleTagManagerUtils, Authenticator, MeasurementUnitConverterService) {
      this.alertasEnum = AlertasEnum;
      this.moment = moment;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.translate = $translate;
      this.serviceVfiltro = ServiceVfiltro;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.authenticator = Authenticator;

      this.authenticator.getUser().then(user => {
        this.dateTimeFormat = this.measurementUnitConverterService.getDateFormat(user.measurementUnits.dateTimeFormat);
      });
    }

    $onInit() {
      this.alerta = this.resolve.alerta;
      this.tipoAlerta = this.alertasEnum.getAlerta(this.alerta.tipo);
      this.inicioVideo = this.moment(this.alerta.datahora).subtract(5, 'seconds').toISOString();
      this.fimVideo = this.moment(this.alerta.datahora).add(10, 'seconds').toISOString();
    }

    getIconBackgroundColor() {
      return `bg-${this.tipoAlerta.cor}`;
    }

    qualificaAlerta(tipoAlerta, nivel) {
      if (nivel) {
        return `${tipoAlerta}_${nivel}`;
      }
      return tipoAlerta;
    }

    requisitarVideo() {
      this.sendEventToGTM();
      this.restangular.all('video/videos')
        .withHttpConfig({transformResponse: angular.identity})
        .post({
          idVeiculo: this.alerta.veiculo.id,
          inicioVideo: this.inicioVideo,
          fimVideo: this.fimVideo,
          descricao: this.translate.instant(this.tipoAlerta.descricao),
          dataHoraEvento: this.alerta.datahora,
          tipoEvento: this.qualificaAlerta(this.tipoAlerta.id, this.alerta.nivel),
          cameras: ['CAMERA_1', 'CAMERA_2', 'CAMERA_3', 'CAMERA_4', 'CAMERA_5']
        })
        .then(() => {
          this.close();
        })
        .catch(() => {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.analise.alertas.requisicaoVideo.errorMsg'),
            appendTo: '.alerta-aqui-requisicao-video'
          });
        });
    }

    sendEventToGTM() {
      this.googleTagManagerUtils.sendEventClickGTM('analise_alertas_solicitar_video', this.serviceVfiltro.factoryVfiltro.user);
    }
  }

  angular
    .module('requisicaoVideo')
    .component('modalRequisicaoVideo', {
      controller: ModalRequisicaoVideoCtrl,
      templateUrl: 'historico-alerta/modal-requisicao-video.tpl.html',
      bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
      }
    });
}());
