(function () {
  'use strict';

  class CarouselSnapshotCtrl {
    constructor($translate, UrlConfigService) {
      this.translate = $translate;
      this.urlConfigService = UrlConfigService;
    }

    $onInit() {
      this.snapshot = this.resolve.snapshot;
      this.indiceCard = this.resolve.indice;
    }

    getUrl(indice) {
      const currentCamera = this.snapshot.cameras.find(cam => cam.indice === indice);
      return currentCamera.possuiImagem ?
        currentCamera.s3Url :
        `https://veltec3g-o-que-ha-de-novo.s3-sa-east-1.amazonaws.com/Veltec+Trimble/veltec-background.png`;
    }

    getCaption(indice) {
      return `${this.translate.instant('ce.mapa.common.visaoCameras.camera')} ${indice + 1}`;
    }
  }

  angular
    .module('historicoTimeline')
    .component('carouselSnapshot', {
      controller: CarouselSnapshotCtrl,
      controllerAs: '$ctrl',
      templateUrl: 'historico-timeline/visao-cameras/carousel-snapshot/carousel-snapshot.tpl.html',
      bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
      }
    });
}());
