/* global document */
(function () {
  'use strict';

  class PlaybackCtrl {
    constructor(ServiceVfiltro, FactoryVfiltro, Keys) {
      this.keys = Keys;

      ServiceVfiltro.init(FactoryVfiltro.get([]));
    }
  }

  angular
    .module('relatorios.videoLibrary.playback')
    .controller('PlaybackCtrl', PlaybackCtrl);
}());
