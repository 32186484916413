/* global _ */
(function () {
  'use strict';
  class OcorrenciaDadosCtrl {

    constructor($translate) {
      this.uoVeiculoI18N = $translate.instant('ce.tempoReal.quadroDeOcorrencias.ocorrenciaDados.uoVeiculo');
      this.grupoVeiculoI18N = $translate.instant('ce.tempoReal.quadroDeOcorrencias.ocorrenciaDados.grupoVeiculo');
      this.uoMotoristaI18N = $translate.instant('ce.tempoReal.quadroDeOcorrencias.ocorrenciaDados.uoMotorista');
      this.departamentoI18N = $translate.instant('ce.tempoReal.quadroDeOcorrencias.ocorrenciaDados.departamento');
    }

    $onInit() {
      this.ocorrencia = _.cloneDeep(this.item);

      if (this.ocorrencia.veiculo) {
        this.linkMapaVeiculo = this.getLinkLocalizacaoFrota();
      }

      if (this.ocorrencia.motorista) {
        this.linkHistoricoOcorrencias = this.getLinkHistoricoOcorrencias();
        this.linkProntuario = this.getLinkProntuario();
      }

      this.buildConjInfos();
    }

    getLinkLocalizacaoFrota() {
      return `mapas-grid?vfiltro=uo:${this.ocorrencia.veiculo.uo.id};veiculos:${this.ocorrencia.veiculo.id}`;
    }

    getLinkProntuario() {
      return `prontuario/${this.ocorrencia.motorista.id}?vfiltro=dateTime:mesAtual`;
    }

    getLinkHistoricoOcorrencias() {
      return `/relatorios/historico-ocorrencia?vfiltro=dateTime:ontem;uo:${this.ocorrencia.motorista.uo.id};motoristas:${this.ocorrencia.motorista.id}`;
    }

    buildConjInfos() {
      this.veiculoInfos = [];
      this.motoristaInfos = [];

      this.veiculoInfos.push(
        this.buildInfo(
          'trimble-icon trimble-ou',
          this.uoVeiculoI18N,
          this.ocorrencia.veiculo.uo.nome
        )
      );
      if (this.ocorrencia.veiculo.grupo) {
        this.veiculoInfos.push(
          this.buildInfo(
            'trimble-icon trimble-group',
            this.grupoVeiculoI18N,
            this.ocorrencia.veiculo.grupo.nome
          )
        );
      }

      if (this.ocorrencia.motorista) {
        this.motoristaInfos.push(
          this.buildInfo(
            'trimble-icon trimble-ou',
            this.uoMotoristaI18N,
            this.ocorrencia.motorista.uo.nome
          )
        );
        if (this.ocorrencia.motorista.departamento) {
          this.motoristaInfos.push(
            this.buildInfo(
              'fa fa-users',
              this.departamentoI18N,
              this.ocorrencia.motorista.departamento.nome
            )
          );
        }
      }
    }

    buildInfo(icon, title, value, link, linkText) {
      return {
        icon, title, description: () => value, link, linkText
      };
    }
  }

  angular
    .module('ocorrencia.component')
    .component('ocorrenciaDados', {
      controller: OcorrenciaDadosCtrl,
      templateUrl: 'ocorrencia/ocorrencia-dados/ocorrencia-dados.tpl.html',
      bindings: {
        item: '='
      }
    });
}());
