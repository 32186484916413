(function () {
  'use strict';

  /* global _ */
  function resumoPeriodo() {
    return {
      templateUrl: 'relatorios/resumo-periodo.tpl.html',
      controller: 'ResumoPeriodoCtrl',
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {
        titulo: '=',
        subtitulo: '=',
        duracao: '=',
        distancia: '=',
        consumoCombustivel: '=',
        mediaConsumo: '=',
        consumoEletrico: '=',
        mediaConsumoEletrico: '=',
        logradouroInicio: '=',
        logradouroFim: '=',
        conducoes: '=',
        tempoPorFaixaRpm: '=',
        onClickEvento: '=',
        onClickPosicao: '=',
        faixasDescida: '=',
        diferencaAltitude: '='
      }
    };
  }

  class ResumoPeriodoCtrl {
    constructor($scope, EventosEnum, $sce, $translate, Eventos, ServiceVfiltro, GoogleTagManagerUtils, $rootScope, MeasurementUnitConverterService) {
      this.scope = $scope;
      this.eventosEnum = EventosEnum;
      this.translate = $translate;
      this.enableTooltipTitulo = false;
      this.enableTooltipSubtitulo = false;
      this.eventoSelecionado = '';
      this.trusted = {};
      this.sce = $sce;
      this.eventos = Eventos;
      this.serviceVfiltro = ServiceVfiltro;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.iconesEventos = {
        [this.translate.instant(this.eventosEnum.EXCESSO_VELOCIDADE)]: 'trimble-speeding',
        [this.translate.instant(this.eventosEnum.ACELERACAO_BRUSCA)]: 'trimble-harsh-acceleration',
        [this.translate.instant(this.eventosEnum.FRENAGEM_BRUSCA)]: 'trimble-harsh-braking',
        [this.translate.instant(this.eventosEnum.CURVA_BRUSCA)]: 'trimble-sharp-turn',
        [this.translate.instant(this.eventosEnum.BANGUELA)]: 'trimble-coasting',
        [this.translate.instant(this.eventosEnum.PARADO_LIGADO)]: 'trimble-idling',
        [this.translate.instant(this.eventosEnum.RPM_EXCESSIVO)]: 'trimble-rpm',
        [this.translate.instant(this.eventosEnum.EXCESSO_VELOCIDADE_VIA)]: 'trimble-speeding',
        [this.translate.instant(this.eventosEnum.SENSOR_ACIONADO)]: 'trimble-sensor-on',
        [this.translate.instant(this.eventosEnum.CAMERA_OBSTRUIDA)]: 'trimble-obstructed-camera'
      };
      this.eventosMedidosPorQuantidade = [
        this.translate.instant(this.eventosEnum.ACELERACAO_BRUSCA),
        this.translate.instant(this.eventosEnum.CURVA_BRUSCA),
        this.translate.instant(this.eventosEnum.FRENAGEM_BRUSCA),
        this.translate.instant(this.eventosEnum.CAMERA_OBSTRUIDA)
      ];
      this.eventosContendoCurva = this.translate.instant(this.eventosEnum.CURVA);
      this.descidaFaixa1 = this.translate.instant(this.eventos.DESCIDA_FAIXA1.tipo);
      this.descidaFaixa2 = this.translate.instant(this.eventos.DESCIDA_FAIXA2.tipo);
      this.scope.$watch('conducoes', conducoes => {
        this.agruparEventosDasConducoes(conducoes);
        this.agruparDescidasDasConducoes(conducoes);
      });
      this.rootScope = $rootScope;
      this.measurementUnitConverterService = MeasurementUnitConverterService;

      this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.rootScope.usuario.measurementUnits.distanceMeasurement);
      this.fuelUnit = this.measurementUnitConverterService.getFuelUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
      this.fuelEfficiency = this.measurementUnitConverterService.getFuelEfficiencyUnit(this.rootScope.usuario.measurementUnits.fuelMeasurement);
    }

    getTipoAlerta(alerta) {
      return this.eventos.getEvento(alerta).alerta;
    }

    agruparEventosDasConducoes(conducoes) {
      if (!conducoes) {
        return;
      }
      this.eventosAgrupados = _.groupBy(
        _.flatten(conducoes.map(conducao => conducao.posicoes)),
        p => p.descricao.includes(this.eventosContendoCurva) ?
          this.translate.instant(this.eventosEnum.CURVA_BRUSCA) :
          p.descricao
      );
      this.eventosAgrupados = Object.keys(this.eventosAgrupados).map(key => {
        return {
          nome: key,
          duracao: this.eventosAgrupados[key]
            .map(posicao => posicao.duracao)
            .reduce((acumulado, atual) => acumulado + atual),
          posicoes: this.eventosAgrupados[key]
        };
      })
      .filter(agrupamento => this.iconesEventos[agrupamento.nome] && agrupamento.posicoes.length > 0);
    }

    agruparDescidasDasConducoes(conducoes) {
      if (!conducoes) {
        return;
      }
      this.descidasAgrupadas = _.groupBy(
        _.flatten(conducoes.map(conducao => conducao.posicoes))
        .filter(p => p.descricao.includes(this.translate.instant(this.eventosEnum.DESCIDA))),
        p => p.evento
      );
      this.descidasAgrupadas = Object.keys(this.descidasAgrupadas).map(key => {
        return {
          nome: key,
          duracao: this.descidasAgrupadas[key]
            .map(posicao => posicao.miscInfo.segundosFreioPressionado)
            .reduce((acumulado, atual) => acumulado + atual),
          posicoes: this.descidasAgrupadas[key]
        };
      })
      .sort((a, b) => a.nome.localeCompare(b.nome))
      .filter(agrupamento => agrupamento.posicoes.length > 0);
    }

    getTextoFaixaDescida(nome) {
      if (nome === this.descidaFaixa1) {
        return this.translate.instant('ce.resumoPeriodo.faixa', {x: 1});
      } else if (nome === this.descidaFaixa2) {
        return this.translate.instant('ce.resumoPeriodo.faixa', {x: 2});
      }
      return this.translate.instant('ce.resumoPeriodo.faixa', {x: 3});
    }

    getTextoTempoFaixaDescida(nome) {
      if (nome === this.descidaFaixa1) {
        return this.translate.instant('ce.resumoPeriodo.ateXseg', {x: this.scope.faixasDescida.segundosFaixa1});
      } else if (nome === this.descidaFaixa2) {
        return this.translate.instant('ce.resumoPeriodo.ateXseg', {x: this.scope.faixasDescida.segundosFaixa2});
      }
      return this.translate.instant('ce.resumoPeriodo.maiorXseg', {x: this.scope.faixasDescida.segundosFaixa2});
    }

    isAnyOfTheseEvents(agrupamentoNome, events) {
      return events.map(event => this.translate.instant(event)).includes(agrupamentoNome);
    }

    tituloCustomizado(reticencias) {
      if (!angular.isArray(this.scope.titulo)) {
        return this.scope.titulo;
      }

      let titulo = _.uniq(this.scope.titulo);

      if (titulo.length > 1) {
        if (reticencias) {
          return titulo[0] + '...';
        }
        this.enableTooltipTitulo = true;
        return titulo.slice(1).join(', ');
      }
      this.enableTooltipTitulo = false;
      return titulo[0];
    }

    subtituloCustomizado(reticencias) {
      if (!angular.isArray(this.scope.subtitulo)) {
        return this.scope.subtitulo;
      }

      let subtitulo = _.uniq(this.scope.subtitulo);

      if (subtitulo.length > 1) {
        if (reticencias) {
          return subtitulo[0] + '...';
        }
        this.enableTooltipSubtitulo = true;
        return subtitulo.slice(1).join(', ');
      }
      this.enableTooltipSubtitulo = false;
      return subtitulo[0];
    }

    montaHorarioViagem() {
      if (angular.isDefined(this.scope.inicio) && angular.isDefined(this.scope.fim)) {
        return this.scope.inicio + ' - ' + this.scope.fim;
      }
    }

    getPopoverContent() {
      const link = 'http://portal.veltec.com.br',
          content = this.translate.instant('ce.resumoPeriodo.popOverContent', {linkPortalVeltec: link});
      return this.trusted[content] || (this.trusted[content] = this.sce.trustAsHtml(content));
    }

    onClick(agrupamento) {
      this.eventoSelecionado = this.eventoSelecionado === agrupamento.nome ? '' : agrupamento.nome;
      this.scope.onClickEvento(this.eventoSelecionado);
    }

    formatarVelocidadeMaximaBanguela(intensidade) {
      if (angular.isString(intensidade) && intensidade.length > 0) {
        return intensidade.match(/\d+/).toString();
      }
      return '';
    }

    formatarVelocidadeMinimaBanguela(intensidade) {
      if (angular.isString(intensidade) && intensidade.length > 0) {
        return intensidade.match(/\d+(?=\,)/).toString();
      }
      return '';
    }

    hasConsumo() {
      return angular.isDefined(this.scope.mediaConsumo);
    }

    deveExibirFaixasRpm() {
      return _.some(this.scope.tempoPorFaixaRpm, Boolean);
    }

    onVideoClick() {
      this.googleTagManagerUtils.sendEventClickGTM('bdv_bdm_detalhes_reproduzir_video_vfleets', this.serviceVfiltro.factoryVfiltro.user);
    }
  }

  angular
    .module('resumoPeriodoModule')
    .controller('ResumoPeriodoCtrl', ResumoPeriodoCtrl)
    .directive('resumoPeriodo', resumoPeriodo);
}());
