(function () {
  'use strict';

  class VisaoCamerasCtrl {
    constructor() {
      this.indice = undefined;
      this.indicesDisponiveis = [];
    }

    $onChanges() {
      if (this.snapshots && this.snapshots.length) {
        this.setIndicesDisponiveis();
      }
    }

    setIndicesDisponiveis() {
      this.indicesDisponiveis = [
        ...new Set(
          this.snapshots.reduce((acc, next) => [...acc, ...next.cameras.map(cam => cam.indice)], [])
        )
      ].sort();

      this.indice = angular.isDefined(this.indice) ? this.indice : this.indicesDisponiveis.indexOf(this.indicesDisponiveis[0]);
    }

    changeIndiceCamera(value) {
      this.indice = value;
    }

    verNoMapa(posicao) {
      this.onVerNoMapa({posicao});
    }

    changePage(page) {
      this.handleChangePage({page});
      angular.element('#snapshots').scrollTop(0);
    }
  }

  angular
    .module('historicoTimeline')
    .component('visaoCameras', {
      controller: VisaoCamerasCtrl,
      templateUrl: 'historico-timeline/visao-cameras/visao-cameras.tpl.html',
      bindings: {
        snapshots: '<',
        onVerNoMapa: '&',
        navigationSetting: '<',
        handleChangePage: '&'
      }
    });
}());
