(function () {
  'use strict';

  angular
    .module('enumModule')
    .constant('EventosEnum', {
      EXCESSO_VELOCIDADE: 'ce.enum.common.excessoVelocidade',
      EXCESSO_VELOCIDADE_VIA: 'ce.enum.common.excessoVelocidadePorVia',
      PARADO_LIGADO: 'ce.enum.common.paradoLigado',
      RPM_EXCESSIVO: 'ce.enum.common.rpmExcessivo',
      BANGUELA: 'ce.enum.common.banguela',
      CURVA_BRUSCA: 'ce.enum.common.curvaBrusca',
      ACELERACAO_BRUSCA: 'ce.enum.common.aceleracaoBrusca',
      FRENAGEM_BRUSCA: 'ce.enum.common.frenagemBrusca',
      FREADA_BRUSCA_COM_ACIONAMENTO_PEDAL: 'ce.enum.common.frenagemBruscaComPedal',
      FREADA_BRUSCA_SEM_ACIONAMENTO_PEDAL: 'ce.enum.common.frenagemBruscaSemPedal',
      POSSIVEL_COLISAO: 'ce.enum.common.possivelColisao',
      CURVA: 'ce.enum.eventos.curva',
      ACELERACAO: 'ce.enum.eventos.aceleracao',
      FREADA: 'ce.enum.eventos.freada',
      TREPIDACAO: 'ce.enum.common.trepidacao',
      VEICULO_SEM_VIGENCIA: 'ce.enum.common.semVigencia',
      PONTO_REFERENCIA: 'ce.enum.eventos.pontoDeReferencia',
      LOCALIZACAO: 'ce.enum.eventos.localizacao',
      ANALISE_OPERACIONAL: 'ce.enum.eventos.analiseOperacional',
      JORNADA: 'ce.enum.eventos.jornada',
      RFID: 'ce.enum.eventos.rfidUpperCase',
      CONEXAO_EQUIPAMENTO: 'ce.enum.eventos.conexaoEquipamento',
      REMOCAO_EQUIPAMENTO: 'ce.enum.common.remocaoEquipamento',
      DIAGNOSTICO_ODB: 'ce.enum.eventos.diagnosticoObd',
      DIAGNOSTICO_CAN: 'ce.enum.eventos.diagnosticoCan',
      STATUS: 'ce.enum.eventos.status',
      MOVIMENTO: 'ce.enum.eventos.movimento',
      PARADO: 'ce.enum.eventos.parado',
      SENSOR_ACIONADO: 'ce.enum.eventos.sensorAcionado',
      MANUTENCAO_PROGRAMADA: 'ce.enum.common.manutencaoProgramada',
      DISTANCIA_PERIGOSA: 'ce.enum.common.distanciaPerigosa',
      DISTRACAO_MOTORISTA: 'ce.enum.common.distracao',
      FADIGA_MOTORISTA: 'ce.enum.common.fadiga',
      MANUSEIO_CELULAR: 'ce.enum.common.usoDeCelular',
      PREVISAO_COLISAO: 'ce.enum.common.quaseColisao',
      USO_CIGARRO: 'ce.enum.common.usoDeCigarro',
      CAMERA_OBSTRUIDA: 'ce.enum.common.cameraObstruida',
      PERMANENCIA_PONTO: 'ce.enum.common.permanenciaPontoReferencia',
      MANUTENCAO_PROGRAMADA_HORIMETRO: 'ce.enum.common.manutencaoProgramadaHorimetro',
      DESCIDA: 'ce.enum.common.descida',
      DESCIDA_FAIXA1: 'ce.enum.eventos.descidaFaixa1',
      DESCIDA_FAIXA2: 'ce.enum.eventos.descidaFaixa2',
      DESCIDA_FAIXA3: 'ce.enum.eventos.descidaFaixa3',
      SEM_CONE: 'ce.enum.eventos.semCone',
      SEM_OCULOS: 'ce.enum.eventos.SemOculos',
      SEM_LUVAS: 'ce.enum.eventos.SemLuvas',
      SEM_EPI: 'ce.enum.eventos.semEPI',
      BOCEJO: 'ce.enum.eventos.bocejo',
      ULTRAPASSAGEM_ILEGAL: 'ce.enum.eventos.ultrapassagemIlegal',
      CARONA: 'ce.enum.eventos.carona'
    });
}());
