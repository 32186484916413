(function () {
  'use strict';

  /* globals moment */
  class RelatorioAgendadoService {
    constructor(FactoryVfiltro, Keys, $translate) {
      this.factoryVfiltro = FactoryVfiltro;
      this.vfiltroKeys = Keys;
      this.translate = $translate;
    }

    getTiposRelatoriosAgendaveis() {
      return {
        HISTORICO_ALERTA: {
          label: this.translate.instant('ce.analise.solicitacoesAgendadas.relatoriosAgendaveis.analiseDeAlertas'),
          tipo: 'HISTORICO_ALERTA',
          permissao: 'HISTORICO_ALERTA_VISUALIZAR',
          getFiltros: (disabled, filtroValues) => {
            return this.factoryVfiltro.get([
              {
                key: this.vfiltroKeys.dateTime.name,
                maxDate: moment().endOf('day').format(),
                dateLimit: 30,
                segment: false,
                disabled: disabled,
                id: 'filtro-modal-datetime',
                defaultValues: {
                  hoje: {active: true},
                  ontem: {active: true}
                }
              },
              {
                name: 'uoId',
                disabled: disabled,
                key: this.vfiltroKeys.uo.name,
                id: 'filtro-modal-uo',
                size: 12
              },
              {
                name: 'uoDonaId',
                disabled: disabled,
                key: this.vfiltroKeys.uoParceira.name,
                id: 'filtro-modal-uo-parceira',
                size: 6
              },
              {
                name: 'idEntidade',
                disabled: disabled,
                key: this.vfiltroKeys.veiculos.name,
                ignoreValidation: true,
                id: 'filtro-modal-veiculo',
                size: 6
              },
              {
                disabled: disabled,
                key: this.vfiltroKeys.status.name,
                id: 'filtro-modal-status',
                size: 6
              },
              {
                disabled: disabled,
                key: this.vfiltroKeys.eventoTic.name,
                id: 'filtro-modal-eventoTic',
                size: 6
              },
              {
                disabled: disabled,
                key: this.vfiltroKeys.nivel.name,
                id: 'filtro-modal-nivel',
                size: 6
              },
              {
                disabled: disabled,
                key: this.vfiltroKeys.tipos.name,
                id: 'filtro-modal-tipos',
                size: 12
              },
              {
                name: 'idUsuario',
                disabled: disabled,
                key: this.vfiltroKeys.usuario.name,
                id: 'filtro-modal-usuario',
                size: 6
              }
            ], false, filtroValues);
          }
        },
        MOVIMENTO_VEICULO: {
          label: this.translate.instant('ce.analise.solicitacoesAgendadas.relatoriosAgendaveis.analiseDeMovimentoDoVeiculo'),
          tipo: 'MOVIMENTO_VEICULO',
          permissao: 'HISTORICO_CONSOLIDADO_VISUALIZAR',
          getFiltros: (disabled, filtroValues) => {
            return this.factoryVfiltro.get([
              {
                key: this.vfiltroKeys.dateTime.name,
                maxDate: moment().endOf('day').format(),
                dateLimit: 30,
                segment: false,
                disabled: disabled,
                id: 'filtro-modal-datetime',
                defaultValues: {
                  hoje: {active: true},
                  ontem: {active: true}
                }
              },
              {
                name: 'uoId',
                disabled: disabled,
                key: this.vfiltroKeys.uo.name,
                id: 'filtro-modal-uo',
                size: 12
              },
              {
                name: 'uoDonaId',
                disabled: disabled,
                key: this.vfiltroKeys.uoParceira.name,
                id: 'filtro-modal-uo-parceira',
                size: 6
              },
              {
                name: 'idEntidade',
                disabled: disabled,
                key: this.vfiltroKeys.veiculos.name,
                ignoreValidation: true,
                id: 'filtro-modal-veiculo',
                size: 6
              },
              {
                name: 'motoristaIds',
                disabled: disabled,
                key: this.vfiltroKeys.motoristas.name,
                ignoreValidation: true,
                id: 'filtro-modal-motorista',
                size: 6
              }
            ], false, filtroValues);
          }
        }
      };
    }
  }

  angular
    .module('relatorios.historicoSolicitacao')
    .service('RelatorioAgendadoService', RelatorioAgendadoService);
}());
