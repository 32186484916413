(function () {
  'use strict';

  /* @ngdoc object
   * @name cadastros
   * @description
   *
   */
  angular
    .module('cadastros', [
      'ui.router',
      'cadastros.veiculo',
      'cadastros.grupoVeic',
      'cadastros.motorista',
      'cadastros.pontoReferencia',
      'cadastros.motorista.documentos'
    ]);
}());
