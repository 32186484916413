(function () {
  'use strict';

  class OcorrenciaCardCtrl {
    constructor($filter, ServiceVfiltro, $translate, AlertasEnum) {
      this.filter = $filter;
      this.serviceVfiltro = ServiceVfiltro;
      this.alertasEnum = AlertasEnum;
      this.alerts = [];

      this.qtdTiposAlertas = 0;
      this.semIdentificacaoI18N = $translate.instant('ce.tempoReal.quadroDeOcorrencias.common.semIdentificacao');
      this.motoristaI18N = $translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.motorista');
      this.usuarioAtribuidoI18n = $translate.instant('ce.tempoReal.quadroDeOcorrencias.ocorrenciaCard.usuarioAtribuido');
      this.semUsuarioAtribuidoI18n = $translate.instant('ce.tempoReal.quadroDeOcorrencias.ocorrenciaCard.semUsuarioAtribuido');
      // não mostrar icone do video até preparar backend
      this.showIconVideo = false;
    }

    $onInit() {
      this.roleVisualizarVideo = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('VIDEO_VISUALIZAR');
      this.temVideo = this.ocorrencia.alertasGerados.some(alerta => alerta.videoIds && alerta.videoIds.length);

      this.infoItemMotorista = {
        icon: 'fa fa-user',
        title: this.motoristaI18N,
        upperCase: true,
        description: () => this.ocorrencia.motorista ? this.ocorrencia.motorista.nome : this.semIdentificacaoI18N
      };

      this.infoItemUsuarioAtribuido = {
        icon: 'fa fa-check-circle-o',
        title: this.usuarioAtribuidoI18n,
        description: () => {
          return this.ocorrencia.usuarioAtribuido && this.ocorrencia.usuarioAtribuido.login ?
            this.ocorrencia.usuarioAtribuido.login :
            this.semUsuarioAtribuidoI18n;
        }
      };

      this.totalAlertas = this.ocorrencia.alertasGerados
        .reduce((sum, alerta) => sum + alerta.quantidade, 0);

      this.handleSortAlerts();
    }

    handleSortAlerts() {
      const alertWithQuantity = this.ocorrencia.alertasGerados.reduce((acc, alerta) => {
        if (!acc[alerta.tipo]) {
          acc[alerta.tipo] = 0;
        }
        acc[alerta.tipo] += alerta.quantidade;
        return acc;
      }, {});

      this.alerts = Object.keys(alertWithQuantity).map(tipo => ({
        tipo,
        quantidade: alertWithQuantity[tipo]
      })).filter(alert => alert.quantidade > 0);

      if (this.eventsOrder && this.eventsOrder.length) {
        this.alerts.sort((a, b) => {
          const aIndex = this.eventsOrder.indexOf(a.tipo),
              bIndex = this.eventsOrder.indexOf(b.tipo);
          return aIndex - bIndex;
        });
      }
    }

    handleDescription(tipo) {
      const alertaTipo = this.alertasEnum.getAlerta(tipo) || {};
      return alertaTipo.descricaoIntermediaria || alertaTipo.descricaoCurta || alertaTipo.descricao;
    }

    handleEventsQuantity() {
      return this.totalAlertas - this.alerts[0].quantidade - this.alerts[1].quantidade;
    }
  }

  angular
    .module('quadroOcorrencias')
    .component('ocorrenciaCard', {
      controller: OcorrenciaCardCtrl,
      templateUrl: 'quadro-ocorrencias/ocorrencia-card/ocorrencia-card.tpl.html',
      bindings: {
        ocorrencia: '=',
        eventsOrder: '='
      }
    });
}());
