(function () {
  'use strict';

  angular
    .module('indicadores.ConducaoEconomica', [
      'restangular',
      'angularMoment',
      'chartModule',
      'confluenceID',
      'indicadores',
      'ConstantModule',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'pascalprecht.translate'
    ])
    .factory('ConducaoEconomica', Restangular => Restangular.service('boletim/painel-conducao-economica'));
}());
