/* global document */
/* eslint-disable no-underscore-dangle */

(function () {
  'use strict';

  angular
    .module('ToastModule', [])
    .factory('ToastService', ['Authenticator', '$document', '$timeout', ToastService]);

  function ToastService(Authenticator, $document, $timeout) {
    let isToastBeingDisplayed = false;

    const service = {
      onHyperLinkClick,
      createAndAppendToastElement,
      displayToastIfNeeded,
      isToastDisplayed: () => isToastBeingDisplayed
    };

    return service;

    function onHyperLinkClick(event) {
      Authenticator.getUser().then(user => {
        if (user.dataOrigin === 'TTC') {
          event.preventDefault();
          displayToastIfNeeded();
        }
      }).catch(handleError);
    }

    function displayToastIfNeeded() {
      ensureToastAdvisorElementExists();
      if (!isToastBeingDisplayed) {
        createAndAppendToastElement();
      }
    }

    function ensureToastAdvisorElementExists() {
      let toastContainer = $document[0].querySelector('#link-unavailable-toast-container');
      if (!toastContainer) {
        toastContainer = $document[0].createElement('div');
        toastContainer.id = 'link-unavailable-toast-container';
        $document[0].body.appendChild(toastContainer);
      }
    }

    function createAndAppendToastElement() {
      const toastContainer = $document[0].querySelector('#link-unavailable-toast-container');
      const toastElement = $document[0].createElement('vfwc-toast-alert-unavailable');
      toastContainer.appendChild(toastElement);
      setOnCloseToastAdvisor(toastElement);
      isToastBeingDisplayed = true;

      $timeout(() => {
        if (isToastBeingDisplayed) {
          closeToastAutomatically(toastElement);
        }
      }, 5000);
    }

    function setOnCloseToastAdvisor(toastElement) {
      toastElement.addEventListener('close', () => {
        isToastBeingDisplayed = false;
      });
    }

    function closeToastAutomatically(toastElement) {
      const toastContainer = $document[0].querySelector('#link-unavailable-toast-container');
      if (toastContainer && toastElement) {
        toastContainer.removeChild(toastElement);
      }
      isToastBeingDisplayed = false;
    }

    function handleError(error) {
      console.error('Failed to get user: ', error);
    }
  }
})();
