(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.historicoSolicitacao', {
      url: '/relatorios/historico-solicitacao?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/historico-solicitacao/historico-solicitacao.tpl.html',
          controller: 'HistoricoSolicitacaoCtrl',
          controllerAs: 'historicoSolicitacao'
        }
      },
      data: {
        titulo: 'ce.analise.solicitacoesAgendadas.titulo',
        roles: ['HISTORICO_ALERTA_VISUALIZAR', 'HISTORICO_CONSOLIDADO_VISUALIZAR']
      }
    });
  }

  angular
    .module('relatorios.historicoSolicitacao')
    .config(pageConfig);
}());
